import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import './AddOperatorStyles.scss';
import { STEPS } from './index';
import { Button, Input } from 'antd';
import * as i18next from 'i18next';
import { localStorageHelper } from 'utils';
import { Dispatch } from 'redux';
import { addOperatorNameRequest } from 'redux/actions/configProvider/add-operator-actions';
import { Footer } from 'Components/Footer/Footer';

interface IProps {
    addOperatorName: Function;
    t: i18next.TFunction;
}

interface IState {
    name: string;
    validationError: string;
}

const NAME = { min: 2, max: 64 };

class StepAddOperator extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        name: localStorageHelper.getChainedValue('user.newOperator.name') || '',
        validationError: '',
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        this.setState({
            name: value,
            validationError: this.getValidationError(value),
        });
    };

    getValidationError = (value: string) => {
        const { t } = this.props;
        const { min, max } = NAME;
        let message = '';

        if (value.trim().length < min) {
            message = t('name_is_too_short');
        } else if (value.trim().length > max) {
            message = t('name_is_too_long');
        }

        return message;
    };

    handleSubmit = () => {
        const { name } = this.state;
        const id = localStorageHelper.getChainedValue('user.newOperator.id');

        if (this.getValidationError(name)) {
            return;
        }

        // if we have id we rename existing operator with this id, otherwise we create the new one
        if (id) {
            this.props.addOperatorName({ name, id });
        } else {
            this.props.addOperatorName({ name });
        }
    };

    render = () => {
        const { t } = this.props;
        const { name, validationError } = this.state;

        return (
            <>
                <ItemComponentWrapper titleKey={STEPS[0]}>
                    <div className="operator-step">
                        <div className="item">
                            <label>*{t('name')}</label>
                            <Input
                                className="operator-name-input"
                                value={name}
                                onChange={this.onChange}
                            />
                        </div>
                        <p className="error">{validationError}</p>
                    </div>
                </ItemComponentWrapper>
                <Footer>
                    <Button
                        shape="round"
                        type="primary"
                        disabled={Boolean(validationError) || name.length === 0}
                        onClick={this.handleSubmit}
                    >
                        {t('continue_save')}
                    </Button>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addOperatorName: (data: any) => dispatch(addOperatorNameRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StepAddOperator));
