import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import CustomTabs from 'Components/CustomTabs';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { localStorageHelper } from 'utils';
import { IOperatorsData, ITab } from 'helpers/interfaces';
import * as i18next from 'i18next';
import OperatorCurrencies from 'ConfigurationProvider/OperatorPage/OperatorCurrencies';
import OperatorGames from 'ConfigurationProvider/OperatorPage/OperatorGames';
import OperatorBrands from 'ConfigurationProvider/OperatorPage/OperatorBrands';
import { Button, Dropdown, MenuProps } from 'antd';
import {
    CheckCircleTwoTone,
    ControlOutlined,
    DollarCircleOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    StopOutlined,
} from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import 'ConfigurationProvider/OperatorPage/OperatorPageStyles.scss';
import { changeOperatorStatus } from 'redux/actions/configProvider/operators-actions';
import {
    getLoadingStatus,
    isStatusChanged,
} from 'redux/selectors/configProvider/operators-selectors';
import { withRouter } from '../../helpers/HOCs/withRouter';

interface IProps {
    changeOperatorStatus: Function;
    isOperatorStatusChanged: boolean;
    t: i18next.TFunction;
    params: any;
    loading: boolean;
}

interface IState {
    operator: IOperatorsData;
    isChangingStatus: boolean;
    isModalVisible: boolean;
    loading: boolean;
}

const OPERATOR_TABS: ITab[] = [
    { name: 'brands', element: <OperatorBrands /> },
    { name: 'games', element: <OperatorGames /> },
    { name: 'currencies', element: <OperatorCurrencies /> },
];

class OperatorPage extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        operator: OperatorPage.getOperatorData(this.props),
        isChangingStatus: false,
        isModalVisible: false,
        loading: false,
    };

    static getOperatorData = (props: IProps) => {
        const { operatorId } = props.params;
        const operators: IOperatorsData[] = localStorageHelper.getChainedValue('user.operators');

        return operators.filter((operator: IOperatorsData) => operator.id === +operatorId)[0];
    };

    static getDerivedStateFromProps = (props: IProps & WithTranslation, state: IState) => {
        const { operator, isChangingStatus, isModalVisible } = state;

        if (props.isOperatorStatusChanged && isChangingStatus) {
            return {
                operator: { ...operator, enabled: !operator.enabled },
                isChangingStatus: false,
            };
        }

        if (state.loading && !props.loading && isModalVisible) {
            return { ...state, loading: false, isModalVisible: false };
        }

        return { ...state, loading: props.loading };
    };

    getActionsMenu = (): MenuProps['items'] => {
        const { enabled, id } = this.state.operator;
        const { t } = this.props;

        const items = [];

        const statusItem = enabled
            ? {
                  key: 'disable_operator',
                  label: (
                      <div onClick={() => this.handleOperatorStatusChange()}>
                          <StopOutlined />
                          {t('disable_operator')}
                      </div>
                  ),
                  className: 'cp-actions__menu__red',
              }
            : {
                  key: 'enable_operator',
                  label: (
                      <div onClick={() => this.handleOperatorStatusChange()}>
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                          {t('enable_operator')}
                      </div>
                  ),
                  className: 'cp-actions__menu__green',
              };

        items.push(statusItem);

        const links = [
            {
                key: 'manage_currencies',
                url: `/config-provider/operators/${id}/manage-currencies`,
                icon: <DollarCircleOutlined />,
                text: t('manage_currencies'),
            },
            {
                key: 'manage_games',
                url: `/config-provider/operators/${id}/manage-games`,
                icon: <PlayCircleOutlined />,
                text: t('manage_games'),
            },
            {
                key: 'edit_game_configurations',
                url: `/config-provider/operators/${id}/game-configurations`,
                icon: <EditOutlined />,
                text: t('edit_game_configurations'),
            },
            {
                key: 'add_brand',
                url: `/config-provider/operators/${id}/add-brand`,
                icon: <PlusOutlined />,
                text: t('add_brand'),
            },
            {
                key: 'manage_brands',
                url: `/config-provider/operators/${id}/edit-brand`,
                icon: <ControlOutlined />,
                text: t('manage_brands'),
            },
        ];

        for (const link of links) {
            const menuItem = {
                key: link.key,
                label: (
                    <Link to={link.url}>
                        {link.icon}
                        {link.text}
                    </Link>
                ),
                className: 'cp-actions__menu',
            };
            items.push(menuItem);
        }

        return items;
    };

    handleOperatorStatusChange = () => {
        const { id, enabled } = this.state.operator;

        this.props.changeOperatorStatus({ enabled: !enabled, id });
        this.setState({ isChangingStatus: true });
    };

    render = () => {
        const { t } = this.props;
        const { id, name } = this.state.operator;

        return (
            <ContentComponent
                header={
                    <div className="header-line cp-header-line">
                        <HeaderTitleComponent
                            title={name}
                            customBreadcrumbs={
                                <Link to={`/config-provider/operators`}>{t('operators')}</Link>
                            }
                        />
                        <div className="cp-header-buttons">
                            <div className="cp-header-buttons__button">
                                <Dropdown
                                    menu={{ items: this.getActionsMenu() }}
                                    trigger={['click']}
                                >
                                    <Button
                                        className={'cp-operator-actions'}
                                        icon={<EllipsisOutlined />}
                                    />
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                }
                innerContent={
                    <>
                        <CustomTabs tabs={OPERATOR_TABS} data={{ operatorId: id }} />
                    </>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    isOperatorStatusChanged: isStatusChanged(state),
    loading: getLoadingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeOperatorStatus: (data: any) => dispatch(changeOperatorStatus(data)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OperatorPage);
export default withTranslation()(withRouter(ConnectedComponent));
