import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { IBrandCurrencyData, IBrandData, ICurrencyData, IOperatorData } from 'helpers/interfaces';
import { localStorageHelper, sortCPCurrenciesArray } from 'utils';
import { Button, Switch } from 'antd';
import {
    getBrandCurrenciesRequest,
    updateBrandCurrenciesRequest,
} from 'redux/actions/configProvider/manage-brand-actions';
import { BRAND_STEPS } from 'ConfigurationProvider/ManageBrands/AddBrand';
import { Loader } from 'Components/Loader/Loader';
import { Footer } from 'Components/Footer/Footer';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons/lib';
import {
    getBrandCurrencies,
    isBrandDataLoading,
} from 'redux/selectors/configProvider/manage-brand-selectors';

interface IProps {
    operatorData: IOperatorData;
    brandData: IBrandData;
    updateBrandData: Function;
    getBrandCurrencies: Function;
    updateBrandCurrencies: Function;
    brandCurrencies?: any;
    loading: boolean;
    previousStep: Function;
    nextStep: Function;
    t: i18next.TFunction;
}

interface IState {
    currencies: any;
    isAvailableOnTop: boolean;
    keyToSort: 'name' | 'code' | 'id';
    sortState: 'asc' | 'desc';
}

const COLUMNS: string[] = ['name', 'id', 'code', 'subunit', 'status'];

class StepManageBrandCurrencies extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        currencies: [],
        isAvailableOnTop: true,
        keyToSort: 'code',
        sortState: 'asc',
    };

    changeAllStatuses = (frozen: boolean) => {
        const { currencies } = this.state;

        currencies.forEach((currency: IBrandCurrencyData) => (currency.frozen = frozen));

        this.setState({ currencies });
    };

    onChange = (checked: boolean, currencyId: number) => {
        const { currencies } = this.state;

        currencies.forEach((currency: IBrandCurrencyData) => {
            if (currencyId === currency.currencyId) {
                currency.frozen = !checked;
            }
        });

        this.setState({ currencies });
    };

    handleSubmit = () => {
        if (this.isContinueBtnEnabled()) {
            this.props.updateBrandCurrencies({
                brandId: this.props.brandData.id,
                currencies: this.state.currencies,
            });
            this.props.updateBrandData({ currencies: this.state.currencies });

            this.setState({ currencies: [] });
        }
    };

    isContinueBtnEnabled = () =>
        this.state.currencies.find((currency: IBrandCurrencyData) => !currency.frozen);

    previousStep = () => this.props.previousStep();

    nextStep = () => {
        this.props.nextStep();
        this.props.updateBrandData({ currencies: this.props.brandCurrencies });
    };

    sortStateHandler = (key: 'code' | 'id' | 'name') => {
        const { keyToSort, sortState } = this.state;

        if (key === keyToSort) {
            if (sortState === 'asc') {
                this.setState({ sortState: 'desc' });
            } else {
                this.setState({ sortState: 'asc' });
            }
        } else {
            this.setState({ keyToSort: key, sortState: 'asc' });
        }
    };

    handleAvailableOnTop = () => {
        this.setState({ isAvailableOnTop: !this.state.isAvailableOnTop });
    };

    getTable = () => {
        const { keyToSort, sortState, isAvailableOnTop, currencies } = this.state;
        const data = sortCPCurrenciesArray(currencies, keyToSort, sortState, isAvailableOnTop);

        return (
            <table className="cp-table">
                <thead>
                    <tr>
                        {COLUMNS.map((columnTitle: string) => (
                            <th key={columnTitle} className="cp-table-cell">
                                <div className="cell-inner">
                                    <div className="cell-inner__content">
                                        {this.props.t(columnTitle)}
                                    </div>
                                    {columnTitle === 'id' ||
                                    columnTitle === 'name' ||
                                    columnTitle === 'code' ? (
                                        <div
                                            className={`sorter${
                                                keyToSort && keyToSort === columnTitle
                                                    ? ` ${sortState}`
                                                    : ''
                                            }`}
                                            onClick={() => this.sortStateHandler(columnTitle)}
                                        >
                                            <CaretUpOutlined />
                                            <CaretDownOutlined />
                                        </div>
                                    ) : null}
                                    {columnTitle === 'status' ? (
                                        <div
                                            className={`sorter${isAvailableOnTop ? ' desc' : ''}`}
                                            onClick={() => this.handleAvailableOnTop()}
                                        >
                                            <CaretUpOutlined />
                                        </div>
                                    ) : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((currency: ICurrencyData) => (
                        <tr key={currency.name}>
                            <td>{currency.name}</td>
                            <td>{currency.id}</td>
                            <td>{currency.code}</td>
                            <td>{currency.subunit}</td>
                            <td>
                                <Switch
                                    checked={!currency.frozen}
                                    onChange={(checked: boolean) =>
                                        this.onChange(checked, currency.id)
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    componentDidUpdate = () => {
        const { brandCurrencies } = this.props;

        if (brandCurrencies && !this.state.currencies.length) {
            const allCurrencies = localStorageHelper.getChainedValue('user.allCurrencies');
            const supplementedCurrencyData: any = [];

            brandCurrencies.forEach((brandCurrency: IBrandCurrencyData) => {
                const currencyData = allCurrencies.find(
                    (currency: any) => brandCurrency.currencyId === currency.id,
                );

                supplementedCurrencyData.push({
                    ...brandCurrency,
                    ...currencyData,
                });
            });

            this.setState({ currencies: supplementedCurrencyData });
        }
    };

    componentDidMount = () => {
        const { brandData } = this.props;
        const { currencies } = this.state;

        if (!Object.keys(currencies)?.length) {
            this.props.getBrandCurrencies(brandData.id);
        }
    };

    render = () => {
        const { t, loading } = this.props;

        return (
            <>
                <div className="operator-step step-wrapper manage-currencies">
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <h2 className="title">{t(BRAND_STEPS[2])}</h2>
                            <div className="buttons">
                                <div
                                    className="btn grey"
                                    onClick={() => this.changeAllStatuses(true)}
                                >
                                    {t('disable_all')}
                                </div>
                                <div
                                    className="btn grey"
                                    onClick={() => this.changeAllStatuses(false)}
                                >
                                    {t('enable_all')}
                                </div>
                            </div>
                            {this.getTable()}
                        </>
                    )}
                </div>
                <Footer>
                    <Button shape="round" onClick={this.previousStep}>
                        {t('back')}
                    </Button>
                    <Button shape="round" onClick={this.nextStep}>
                        {t('next')}
                    </Button>
                    <Button
                        shape="round"
                        type="primary"
                        disabled={!this.isContinueBtnEnabled()}
                        onClick={this.handleSubmit}
                    >
                        {t('continue_save')}
                    </Button>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    loading: isBrandDataLoading(state),
    brandCurrencies: getBrandCurrencies(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBrandCurrencies: (data: any) => dispatch(getBrandCurrenciesRequest(data)),
    updateBrandCurrencies: (data: any) => dispatch(updateBrandCurrenciesRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(StepManageBrandCurrencies));
