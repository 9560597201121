import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { Button, Switch } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import { ICurrencyData } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined } from '@ant-design/icons/lib';
import { Link, Navigate, Params } from 'react-router-dom';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { currencyUtils, CPUtils, sortCPCurrenciesArray } from 'utils';
import {
    getOperatorCurrencies,
    updateOperatorCurrencies,
} from 'redux/actions/configProvider/operators-actions';
import { getDefaultConfiguredCurrencies } from 'redux/actions/configProvider/general-actions';
import { Footer } from 'Components/Footer/Footer';
import {
    getCurrentOperatorCurrencies,
    getOperatorCurrenciesUpdateStatus,
} from 'redux/selectors/configProvider/operators-selectors';
import { getDefConfiguredCurrencies } from 'redux/selectors/configProvider/general-cp-selectors';
import { withRouter } from '../../../../helpers/HOCs/withRouter';

interface IProps {
    params: Params;
    data: string[];
    configuredCurrencies: any[];
    getOperatorCurrencies: Function;
    updateOperatorCurrencies: Function;
    getDefaultConfiguredCurrencies: Function;
    isOperatorCurrenciesUpdated: boolean;
}

type DataKeysForSorting = 'id' | 'name' | 'code' | 'status';

interface IState {
    currencies: any;
    isSubmitEnabled: boolean;
    keyToSort: DataKeysForSorting;
    sortState: 'asc' | 'desc';
}

const COLUMNS: string[] = ['№', 'name', 'id', 'code', 'status', 'subunit'];

class ManageCurrencies extends React.Component<IProps & WithTranslation> {
    state: IState = {
        currencies: {},
        isSubmitEnabled: false,
        keyToSort: 'status',
        sortState: 'desc',
    };

    componentDidUpdate = (prevProps: Readonly<IProps & WithTranslation>, prevState: IState) => {
        const { data, configuredCurrencies } = this.props;
        let isSubmitEnabled = false;

        if (data && configuredCurrencies && !Object.keys(prevState.currencies).length) {
            const currencies: any = {};

            configuredCurrencies.forEach((defCurrency: string) => {
                const item = currencyUtils.getCurrencyDataById(Number(defCurrency));
                let enabledUnderOperator = false;
                let status = false;

                data.forEach((operatorCurrency: any) => {
                    if (defCurrency === operatorCurrency.currencyId) {
                        if (!operatorCurrency.frozen) {
                            status = true;
                        }
                        enabledUnderOperator = true;
                        isSubmitEnabled = true;
                    }
                });
                item.enabledUnderOperator = enabledUnderOperator;
                item.status = status;

                currencies[defCurrency] = item;
            });

            this.setState({ currencies, isSubmitEnabled });
        }
    };

    sortStateHandler = (key: DataKeysForSorting) => {
        const { keyToSort, sortState } = this.state;

        if (key === keyToSort) {
            if (sortState === 'asc') {
                this.setState({ sortState: 'desc' });
            } else {
                this.setState({ sortState: 'asc' });
            }
        } else {
            this.setState({ keyToSort: key, sortState: 'asc' });
        }
    };

    disableAll = () => {
        const { currencies } = this.state;

        for (const currencyId in currencies) {
            if (Object.prototype.hasOwnProperty.call(currencies, currencyId)) {
                currencies[currencyId].status = false;
            }
        }

        this.setState({ currencies, isSubmitEnabled: false });
    };

    enableAll = () => {
        const { currencies } = this.state;

        for (const currencyId in currencies) {
            if (Object.prototype.hasOwnProperty.call(currencies, currencyId)) {
                currencies[currencyId].status = true;
            }
        }

        this.setState({ currencies, isSubmitEnabled: true });
    };

    onChange = (checked: boolean, currencyId: number) => {
        let isSubmitEnabled = false;
        const { currencies } = this.state;

        currencies[currencyId].status = checked;

        for (const currencyId in currencies) {
            if (
                Object.prototype.hasOwnProperty.call(currencies, currencyId) &&
                currencies[currencyId].status
            ) {
                isSubmitEnabled = true;
            }
        }

        this.setState({ currencies, isSubmitEnabled });
    };

    getTable = (originData: ICurrencyData[]) => {
        const { keyToSort, sortState } = this.state;
        const data = sortCPCurrenciesArray(originData, keyToSort, sortState);

        return (
            <ItemComponentWrapper customClass="cp-table__wrapper">
                <table className="cp-table">
                    <thead className="cp-table__header">
                        <tr>
                            {COLUMNS.map((columnTitle: string) => (
                                <th key={columnTitle} className="cp-table-cell">
                                    <div className="cell-inner">
                                        <div className="cell-inner__content">
                                            {this.props.t(columnTitle)}
                                        </div>
                                        {columnTitle === 'id' ||
                                        columnTitle === 'name' ||
                                        columnTitle === 'status' ||
                                        columnTitle === 'code' ? (
                                            <div
                                                className={`sorter${
                                                    keyToSort && keyToSort === columnTitle
                                                        ? ` ${sortState}`
                                                        : ''
                                                }`}
                                                onClick={() => this.sortStateHandler(columnTitle)}
                                            >
                                                <CaretUpOutlined />
                                                <CaretDownOutlined />
                                            </div>
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="cp-table__body">
                        {data.map((currency: ICurrencyData, i: number) => (
                            <tr key={currency.name}>
                                <td className="cp-table__cell cp-table__cell__number">{i + 1}</td>
                                <td className="cp-table__cell cp-table__cell__name">
                                    {currency.name}
                                </td>
                                <td className="cp-table__cell">{currency.id}</td>
                                <td className="cp-table__cell">{currency.code}</td>
                                <td className="cp-table__cell">
                                    <Switch
                                        checked={currency.status}
                                        onChange={(checked: boolean) =>
                                            this.onChange(checked, currency.id)
                                        }
                                    />
                                </td>
                                <td className="cp-table__cell">{currency.subunit}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ItemComponentWrapper>
        );
    };

    saveChanges = () => {
        const { currencies } = this.state;
        const body: any = {
            currencies: [],
            operatorId: this.props.params.operatorId,
        };

        for (const currencyId in currencies) {
            if (Object.prototype.hasOwnProperty.call(currencies, currencyId)) {
                const { enabledUnderOperator, status } = currencies[currencyId];

                (enabledUnderOperator || status) &&
                    body.currencies.push({ id: currencyId, frozen: !status });
            }
        }

        this.props.updateOperatorCurrencies(body);
    };

    componentDidMount = () => {
        const { params, getOperatorCurrencies, getDefaultConfiguredCurrencies } = this.props;

        getOperatorCurrencies(params.operatorId);
        getDefaultConfiguredCurrencies();
    };

    render = () => {
        const { currencies, isSubmitEnabled } = this.state;
        const { t, params, isOperatorCurrenciesUpdated } = this.props;
        const operatorName = CPUtils.operatorIdToName(params.operatorId);

        return isOperatorCurrenciesUpdated ? (
            <Navigate to={`/config-provider/operators`} />
        ) : (
            <ContentComponent
                header={
                    <>
                        <div className="header-line cp-header-line">
                            <HeaderTitleComponent
                                title={t('manage_currencies')}
                                customBreadcrumbs={
                                    <>
                                        <Link to={`/config-provider/operators`}>
                                            {t('operators')}
                                        </Link>
                                        <span className="breadcrumbs-separator">/</span>
                                        <Link to={`/config-provider/operator/${params.operatorId}`}>
                                            {operatorName}
                                        </Link>
                                    </>
                                }
                            />
                            <Link to={`/config-provider/operators`}>
                                <CloseOutlined className="close-button" />
                            </Link>
                        </div>
                        <div className="cp-operator-upper-buttons">
                            <div className="btn white" onClick={this.enableAll}>
                                {t('enable_all')}
                            </div>
                            <div className="btn red" onClick={this.disableAll}>
                                {t('disable_all')}
                            </div>
                        </div>
                    </>
                }
                innerContent={
                    currencies && Object.keys(currencies).length ? (
                        this.getTable(Object.values(currencies))
                    ) : (
                        <Loader />
                    )
                }
                footer={
                    <Footer>
                        <Link to={`/config-provider/operators`} className="cp-table-footer__item">
                            <Button shape="round">{t('cancel')}</Button>
                        </Link>
                        <Button
                            shape="round"
                            type="primary"
                            disabled={!isSubmitEnabled}
                            onClick={() => isSubmitEnabled && this.saveChanges()}
                        >
                            {t('save')}
                        </Button>
                    </Footer>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getCurrentOperatorCurrencies(state),
    configuredCurrencies: getDefConfiguredCurrencies(state),
    isOperatorCurrenciesUpdated: getOperatorCurrenciesUpdateStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorCurrencies: (data: any) => dispatch(getOperatorCurrencies(data)),
    getDefaultConfiguredCurrencies: (data: any) => dispatch(getDefaultConfiguredCurrencies(data)),
    updateOperatorCurrencies: (data: any) => dispatch(updateOperatorCurrencies(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(ManageCurrencies)));
