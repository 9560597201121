import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { HeaderTitleComponent } from '../../../../Components/ContentComponent/HeaderTitleComponent';
import { Link, useNavigate } from 'react-router-dom';
import { ContentComponent } from '../../../../Components/ContentComponent/ContentComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema } from './validationSchema';
import ConfigurationTab from './ConfigurationTab';
import PlayersTab from './PlayersTab';
import GamesTab from './GamesTab';
import { useDispatch, useSelector } from 'react-redux';
import {
    createAwardedPromotion,
    resetNewPromotion,
} from '../../../../redux/actions/backoffice/promotions-actions';
import { campaignHasBeenCreatedData } from '../../../../redux/selectors/backoffice/propmotions-selectors';
import dayjs, { Dayjs } from 'dayjs';

const { TabPane } = Tabs;

export interface IAwardedCampaignProps {
    campaign_name: string;
    timezone: 'Etc/UTC';
    start_at: Dayjs | null;
    end_at: Dayjs | null;
    number_of_players: number;
    valid_for: number | null;
    strategy: string;
    start_event: string;
    players: string[];
    currency: string;
    subunit: number;
    price: number | null;
    number_of_features: number | null;
    gameIds: string[];
    status: 'UPCOMING' | 'ONGOING' | 'ENDED' | 'DISABLED' | null;
    allowPostExpirationCompletion: boolean;
    rules_url: string;
    id?: number;
}

const getInitialValues = (): IAwardedCampaignProps => {
    return {
        campaign_name: '',
        timezone: 'Etc/UTC',
        start_at: null,
        end_at: null,
        number_of_players: 1000,
        valid_for: null,
        strategy: 'FIRST_N_PLAYERS',
        start_event: 'ACCEPT',
        players: [],
        currency: 'USD',
        subunit: 100,
        price: 0,
        number_of_features: null,
        gameIds: [],
        allowPostExpirationCompletion: false,
        rules_url: '',
        status: null,
    };
};

const NewAwardedPromotion = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const campaignHasBeenCreated = useSelector(campaignHasBeenCreatedData);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [discardConfirmVisible, setDiscardConfirmVisible] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);

    const initialValues = getInitialValues();
    const formik = useFormik<IAwardedCampaignProps>({
        initialValues,
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            setFormValues(values);
            setConfirmVisible(true);
        },
    });

    const tabHasErrors = (fields: Array<keyof IAwardedCampaignProps>) =>
        fields.some((field) => formik.errors[field] && formik.touched[field]);

    const tabsFields: any = {
        configuration: [
            'campaign_name',
            'timezone',
            'start_at',
            'end_at',
            'valid_for',
            'start_event',
            'allowPostExpirationCompletion',
            'rules_url',
        ],
        players: ['number_of_players', 'strategy', 'players'],
        games: ['currency', 'price', 'number_of_features', 'gameIds'],
    };

    useEffect(() => {
        if (campaignHasBeenCreated) {
            navigate('/casino/awarded-promotions');
        }

        return () => {
            dispatch(resetNewPromotion());
        };
    }, [campaignHasBeenCreated, dispatch, navigate]);

    const handleDiscard = () => {
        setDiscardConfirmVisible(true);
    };

    const confirmDiscard = () => {
        formik.resetForm();
        navigate('/casino/awarded-promotions'); // Change this to the appropriate route
        setDiscardConfirmVisible(false);
    };

    const handleConfirmSubmit = () => {
        dispatch(createAwardedPromotion(formValues));
        setConfirmVisible(false);
    };

    const costPerPlayer = useMemo(() => {
        const { price, number_of_features, subunit } = formik.values;

        if (price && number_of_features) {
            return number_of_features * (price / subunit);
        }

        return 0;
    }, [formik.values]);

    const totalPlayers = useMemo(() => {
        const { strategy, number_of_players, players } = formik.values;

        if (strategy === 'FIRST_N_PLAYERS' && number_of_players) {
            return number_of_players;
        }
        if (strategy === 'PLAYER_LIST' && players?.length > 0) {
            return players.length;
        }

        return 0;
    }, [formik.values]);

    const calculatedTotalCost = useMemo(() => {
        return costPerPlayer * totalPlayers;
    }, [costPerPlayer, totalPlayers]);

    return (
        <ContentComponent
            header={
                <div className="flex justify-between">
                    <HeaderTitleComponent
                        title={'Awarded Feature'}
                        customBreadcrumbs={
                            <Link to={`/casino/awarded-promotions`}>{'Promotions'}</Link>
                        }
                    />
                    <div className="flex items-end">
                        <Button size="large" shape="round" onClick={handleDiscard}>
                            Discard Campaign
                        </Button>
                        <Button
                            danger
                            className="ml-2"
                            size="large"
                            shape="round"
                            onClick={formik.submitForm}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            }
            innerContent={
                <div style={{ padding: 24, backgroundColor: '#fff', borderRadius: 8 }}>
                    <Modal
                        title="Confirm Promotion Submission"
                        open={confirmVisible}
                        onOk={handleConfirmSubmit}
                        onCancel={() => setConfirmVisible(false)}
                    >
                        {formValues && (
                            <div className="space-y-4 p-4  rounded-lg border-dashed">
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">
                                        Campaign Name:
                                    </span>
                                    <span className="text-gray-900">
                                        {formValues.campaign_name}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">Timezone:</span>
                                    <span className="text-gray-900">{formValues.timezone}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">Start Date:</span>
                                    <span className="text-gray-900">
                                        {dayjs(formValues.start_at).format('HH:mm  MMMM D, YYYY')}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">End Date:</span>
                                    <span className="text-gray-900">
                                        {dayjs(formValues.end_at).format('HH:mm MMMM D, YYYY')}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">
                                        Number of Players:
                                    </span>
                                    <span className="text-gray-900">{totalPlayers}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-700 font-bold">Total cost:</span>
                                    <span className="text-gray-900 font-bold">
                                        {calculatedTotalCost} {formValues.currency}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        title="Are you sure you want to discard this campaign?"
                        open={discardConfirmVisible}
                        onOk={confirmDiscard}
                        onCancel={() => setDiscardConfirmVisible(false)}
                    />
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Tabs defaultActiveKey="1">
                                <TabPane
                                    tab={`Configuration ${tabHasErrors(tabsFields.configuration) ? '!' : ''}`}
                                    key="1"
                                >
                                    <ConfigurationTab />
                                </TabPane>
                                <TabPane
                                    tab={`Players ${tabHasErrors(tabsFields.players) ? '!' : ''}`}
                                    key="2"
                                >
                                    <PlayersTab formik={formik} />
                                </TabPane>
                                <TabPane
                                    tab={`Games ${tabHasErrors(tabsFields.games) ? '!' : ''}`}
                                    key="3"
                                >
                                    <GamesTab formik={formik} />
                                </TabPane>
                            </Tabs>

                            <div style={{ marginTop: 24, textAlign: 'right' }}></div>
                        </Form>
                    </FormikProvider>
                </div>
            }
        />
    );
};

export default NewAwardedPromotion;
