import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { Loader } from 'Components/Loader/Loader';
import {
    getOperatorBrandsRequest,
    sortOperatorBrands,
    updateBrandStatusRequest,
} from 'redux/actions/configProvider/manage-brand-actions';
import { IBrandData } from 'helpers/interfaces';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Empty, MenuProps } from 'antd';
import {
    getOperatorBrandsCP,
    getSortedOperatorBrandsCP,
    isBrandDataLoading,
} from 'redux/selectors/configProvider/manage-brand-selectors';
import {
    CheckCircleTwoTone,
    ControlOutlined,
    EllipsisOutlined,
    StopOutlined,
} from '@ant-design/icons';
import CustomTable from '../../../Components/Table/CustomTable';
import { CP_OperatorBrandsData } from '../../../Containers/Reports/columnsData';

interface IOperatorData {
    operatorId: number;
}

interface IProps {
    data?: IOperatorData;
    getOperatorBrands: Function;
    brands: IBrandData[];
    sortedBrands: IBrandData[];
    sortHandler: Function;
    updateBrandStatus: Function;
    updatingBrand: boolean;
    t: i18next.TFunction;
}

interface IState {
    isBrandChangingStatus: boolean;
    isModalVisible: boolean;
    confirmLoading: boolean;
    brandIdToLogout: number;
    brandNameToLogout: string;
}

class OperatorBrands extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        isBrandChangingStatus: false,
        isModalVisible: false,
        confirmLoading: false,
        brandIdToLogout: 0,
        brandNameToLogout: '',
    };

    static getDerivedStateFromProps = (props: IProps & WithTranslation, state: IState) => {
        const { brands, updatingBrand } = props;
        const { isBrandChangingStatus, isModalVisible, confirmLoading } = state;

        if (brands?.length && !updatingBrand && isBrandChangingStatus) {
            return { ...state, isBrandChangingStatus: false };
        }

        if (confirmLoading && !updatingBrand && isModalVisible) {
            return { ...state, confirmLoading: false, isModalVisible: false };
        }

        return { ...state, confirmLoading: updatingBrand };
    };

    componentDidMount = () => {
        this.props.getOperatorBrands(this.props.data?.operatorId);
    };

    getActions = (brand: IBrandData) => (
        <Dropdown menu={{ items: this.getActionsMenuItems(brand) }} trigger={['click']}>
            <Button className={'cp-actions'} icon={<EllipsisOutlined />} />
        </Dropdown>
    );

    getActionsMenuItems = (brand: IBrandData): MenuProps['items'] => {
        const { enabled, operatorId, id } = brand;
        const { t } = this.props;
        const items = [];

        const statusItem = enabled
            ? {
                  key: 'disable',
                  label: (
                      <div onClick={() => this.handleBrandStatusChange(brand)}>
                          <StopOutlined />
                          {t('disable_brand')}
                      </div>
                  ),
                  className: 'cp-actions__menu__red',
              }
            : {
                  key: 'enable',
                  label: (
                      <div onClick={() => this.handleBrandStatusChange(brand)}>
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                          {t('enable_brand')}
                      </div>
                  ),
                  className: 'cp-actions__menu__green',
              };

        items.push(statusItem);

        const manageItem = {
            key: 'manage',
            label: (
                <Link to={`/config-provider/operators/${operatorId}/edit-brand#${id}`}>
                    <ControlOutlined />
                    {t('manage_brand')}
                </Link>
            ),
            className: 'cp-actions__menu',
        };

        items.push(manageItem);

        return items;
    };

    handleBrandStatusChange = (brand: IBrandData) => {
        this.props.updateBrandStatus({ ...brand, enabled: !brand.enabled });
        this.setState({ isBrandChangingStatus: true });
    };

    sortHandler = (key: string, sortState: string) =>
        this.props.sortHandler({ keyToSort: key, sortState });

    render = () => {
        const { brands, sortedBrands, t } = this.props;
        const brandsData =
            sortedBrands && sortedBrands.length
                ? sortedBrands
                : brands
                  ? brands.length
                      ? brands
                      : []
                  : null;

        return brandsData ? (
            brandsData.length ? (
                <>
                    <CustomTable
                        reportKey={'CP_OperatorBrandsData'}
                        dataSource={brandsData}
                        columns={CP_OperatorBrandsData}
                        sortHandler={this.sortHandler}
                        actionsMenu={this.getActions}
                        withoutCollapsing
                        hidePagination
                    />
                </>
            ) : (
                <Empty description={t('no_data')} />
            )
        ) : (
            <Loader />
        );
    };
}

const mapStateToProps = (state: any) => ({
    brands: getOperatorBrandsCP(state),
    sortedBrands: getSortedOperatorBrandsCP(state),
    updatingBrand: isBrandDataLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorBrands: (data: any) => dispatch(getOperatorBrandsRequest(data)),
    updateBrandStatus: (data: any) => dispatch(updateBrandStatusRequest(data)),
    sortHandler: (data: any) => dispatch(sortOperatorBrands(data)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OperatorBrands);
export default withTranslation()(ConnectedComponent);
