import React, { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import './UnfinishedRoundsStyles.scss';
import { Empty, Pagination, DatePicker, Space, Modal, Button } from 'antd';
import { ContentComponent } from '../../Components/ContentComponent/ContentComponent';
import { Loader } from '../../Components/Loader/Loader';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SimplifiedCustomTable from '../../Components/Table/SimplifiedCustomTable';
import { HeaderTitleComponent } from '../../Components/ContentComponent/HeaderTitleComponent';
import { Input, Select } from 'antd';
import {
    applyAction,
    getUnfinishedRounds,
} from '../../redux/actions/configProvider/unfinished-rounds-actions';
import {
    getTotalNumberOfElementsData,
    getUnfinishedRoundsData,
} from '../../redux/selectors/configProvider/unfinished-rounds-selector';
import { unfinishedRoundsData } from '../../Containers/Reports/columnsData';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface IProps {
    data: IUnfinishedRoundData[];
    getUnfinishedRounds: Function;
    totalItems: number;
    applyAction: Function;
}

interface IUnfinishedRoundData {
    brandId: number;
    brandName: string;
    externalPlayerId: string;
    gameId: number;
    roundUuid: string;
    sessionId: number;
    startId: number;
    startedAt: string;
}

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;
const PAGE_SIZE_ARRAY = ['10', '20', '50', '100'];
const UNFINISHED_PERIODS = [
    '30_MINUTE',
    '60_MINUTE',
    '3_HOUR',
    '6_HOUR',
    '12_HOUR',
    '24_HOUR',
    '48_HOUR',
    '64_HOUR',
];
const SEARCH_OPTIONS = ['brandId', 'gameId', 'sessionId', 'transactionUuid', 'externalPlayerId'];

const initialState: any = {
    picker: 'period',
    date: {
        roundStartedFrom: '',
        roundStartedTo: '',
    },
    filterData: {
        filterKey: 'brandId',
        filterString: '',
    },
    period: 'allPeriods',
    paginationData: {
        pageNumber: 1,
        pageSize: 20,
        sortBy: 'startedAt',
        order: 'DESCENDING',
    },
    totalRounds: 0,
    roundsToFinish: [],
    isTimeout: false,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'change_picker': {
            if (action.picker === 'date') {
                return {
                    ...state,
                    picker: action.picker,
                    period: initialState.period,
                };
            } else {
                return {
                    ...state,
                    picker: action.picker,
                    date: initialState.date,
                };
            }
        }
        case 'change_date': {
            return {
                ...state,
                date: action.date,
            };
        }
        case 'change_pagination': {
            return {
                ...state,
                paginationData: {
                    ...state.paginationData,
                    ...action.paginationData,
                },
            };
        }
        case 'change_filter': {
            return {
                ...state,
                filterData: action.filterData,
            };
        }
        case 'change_period': {
            return {
                ...state,
                period: action.period,
                date: initialState.date,
            };
        }
        case 'set_total_rounds': {
            return {
                ...state,
                totalRounds: action.totalItems,
            };
        }
        case 'set_rounds_to_finish': {
            if (state.roundsToFinish.includes(action.roundUuid)) {
                const roundsToFinish = [...state.roundsToFinish];
                roundsToFinish.splice(roundsToFinish.indexOf(action.roundUuid), 1);
                return {
                    ...state,
                    roundsToFinish,
                };
            } else {
                return {
                    ...state,
                    roundsToFinish: [...state.roundsToFinish, action.roundUuid],
                };
            }
        }
        case 'clear_rounds_to_finish': {
            return {
                ...state,
                roundsToFinish: [],
            };
        }
        case 'set_is_timeout': {
            return {
                ...state,
                isTimeout: action.isTimeout,
            };
        }

        default: {
            return state;
        }
    }
};

const UnfinishedRoundsPage = (props: IProps) => {
    const [actionModalVisible, setActionModalVisible] = React.useState(false);
    const [action, setAction] = React.useState('COMPLETE');
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { data, getUnfinishedRounds, totalItems, applyAction } = props;
    const {
        date,
        roundsToFinish: roundsToProcess,
        filterData,
        paginationData,
        totalRounds,
        period,
        picker,
        isTimeout,
    } = state;

    const requestUnfinishedRounds = useCallback(() => {
        getUnfinishedRounds({
            ...filterData,
            ...paginationData,
            ...date,
            period,
            picker,
        });
    }, [filterData, paginationData, date, period, picker, getUnfinishedRounds]);

    const applyActionButtonHandler = () => {
        setActionModalVisible(true);
    };

    const handleProcessClick = () => {
        setActionModalVisible(false);
        if (isTimeout) return;

        dispatch({ type: 'set_is_timeout', isTimeout: true });
        applyAction({ roundIds: roundsToProcess, unfinishedRoundActionType: action });

        setTimeout(() => {
            dispatch({ type: 'set_is_timeout', isTimeout: false });
            dispatch({ type: 'clear_rounds_to_finish' });

            requestUnfinishedRounds();
        }, 5000);
    };

    const onDateChange = (dates: any) => {
        const formatString = 'YYYY-MM-DDTHH:mm:ss';
        const roundStartedFrom = dates ? dates[0].startOf('day').format(formatString) : null;
        const roundStartedTo = dates ? dates[1].endOf('day').format(formatString) : null;

        dispatch({
            type: 'change_date',
            date: { roundStartedFrom, roundStartedTo },
        });
    };

    const handlePagination = (pageNumber: number, pageSize: number = paginationData.pageSize) => {
        dispatch({
            type: 'change_pagination',
            paginationData: { pageNumber, pageSize },
        });
    };

    const onRowSelect = (row: IUnfinishedRoundData) => {
        dispatch({ type: 'set_rounds_to_finish', roundUuid: row.roundUuid });
    };

    const handleFilterStringChange = (e: any) => {
        dispatch({
            type: 'change_filter',
            filterData: { ...filterData, filterString: e.target.value },
        });
    };

    const handleFilterKeyChange = (filterKey: any) => {
        dispatch({
            type: 'change_filter',
            filterData: { ...filterData, filterKey },
        });
    };

    useEffect(() => {
        dispatch({ type: 'set_total_rounds', totalItems });
    }, [totalItems]);

    useEffect(() => {
        requestUnfinishedRounds();
    }, [paginationData, requestUnfinishedRounds]);

    return (
        <>
            <ContentComponent
                header={
                    <>
                        <HeaderTitleComponent
                            title={t('unfinished_rounds')}
                            customBreadcrumbs={<div>{t('all')}</div>}
                        />
                        <Space.Compact
                            className="broken-rounds-search__wrapper"
                            style={{ marginBottom: '8px', width: '100%' }}
                        >
                            <Select
                                value={picker}
                                onChange={(picker) => dispatch({ type: 'change_picker', picker })}
                            >
                                <Option value="date">{t('date')}</Option>
                                <Option value="period">{t('period')}</Option>
                            </Select>
                            {picker === 'date' ? (
                                <RangePicker
                                    className="broken-rounds-search__datepicker"
                                    onChange={(e: any) => onDateChange(e)}
                                />
                            ) : (
                                <Select
                                    value={period}
                                    onChange={(period) =>
                                        dispatch({
                                            type: 'change_period',
                                            period,
                                        })
                                    }
                                >
                                    {[
                                        <Option key="period0" value="allPeriods">
                                            All Periods
                                        </Option>,
                                        ...UNFINISHED_PERIODS.map((period: string) => (
                                            <Option key={period} value={period}>
                                                {t(period)}
                                            </Option>
                                        )),
                                    ]}
                                </Select>
                            )}
                            <Select
                                style={{ width: '230px' }}
                                className="unfinished-rounds-search-selector"
                                value={filterData.filterKey}
                                onChange={handleFilterKeyChange}
                            >
                                {SEARCH_OPTIONS.map((key: string) => (
                                    <Option key={key} value={key}>
                                        {t(key)}
                                    </Option>
                                ))}
                            </Select>
                            <Search
                                placeholder="input search text"
                                value={filterData.filterString}
                                onChange={handleFilterStringChange}
                                onSearch={() => {
                                    handlePagination(1);
                                    requestUnfinishedRounds();
                                }}
                                enterButton
                                suffix={
                                    <CloseCircleOutlined
                                        className={`broken-rounds-search__clear${
                                            filterData.filterString.length > 0 ? '__visible' : ''
                                        }`}
                                        onClick={() => {
                                            dispatch({
                                                type: 'change_filter',
                                                filterData: {
                                                    ...filterData,
                                                    filterString: '',
                                                },
                                            });
                                        }}
                                    />
                                }
                            />
                        </Space.Compact>
                        <Button
                            shape="round"
                            type="primary"
                            className="unfinished-rounds-process-button"
                            disabled={!roundsToProcess.length || isTimeout}
                            onClick={applyActionButtonHandler}
                        >
                            <>
                                {isTimeout && <LoadingOutlined />}{' '}
                                {`${t('apply_action')}${
                                    roundsToProcess.length ? ` (${roundsToProcess.length})` : ''
                                }`}
                            </>
                        </Button>
                        <Link
                            id="cp-header-button__wrapper"
                            to={`/config-provider/unfinished-rounds/job-schedule`}
                        >
                            <Button
                                shape="round"
                                type="primary"
                                className="unfinished-rounds-process-button"
                            >
                                <>{t('view_job_schedule')}</>
                            </Button>
                        </Link>
                    </>
                }
                innerContent={
                    data ? (
                        data.length ? (
                            <>
                                <SimplifiedCustomTable
                                    dataSource={data}
                                    columns={unfinishedRoundsData}
                                    onRowSelect={onRowSelect}
                                />
                                <Pagination
                                    className="table-pagination"
                                    onChange={handlePagination}
                                    total={totalRounds}
                                    current={paginationData.pageNumber}
                                    pageSize={paginationData.pageSize}
                                    pageSizeOptions={PAGE_SIZE_ARRAY}
                                    showSizeChanger
                                    onShowSizeChange={handlePagination}
                                />
                            </>
                        ) : (
                            <Empty description={t('no_data')} />
                        )
                    ) : (
                        <Loader style={{ height: '90vh' }} />
                    )
                }
            />
            <Modal
                className="unfinished-round-action-modal"
                open={actionModalVisible}
                title={'Apply action'}
                onOk={() => {
                    handleProcessClick();
                }}
                onCancel={() => setActionModalVisible(false)}
                okText={t('apply_action_ok')}
                cancelText={t('Cancel')}
            >
                <label>
                    {t('complete_rounds_confirmation', { numberOfRounds: roundsToProcess.length })}
                </label>
                <br />
                <label>{t('job_action_label')}</label>
                <br />
                <Select
                    className="unfinished-round-jobs-modal__status"
                    defaultValue="COMPLETE"
                    onChange={(e: any) => {
                        setAction(e);
                    }}
                    popupMatchSelectWidth={true}
                >
                    <Option value="UNBLOCK">{t('job_unblock_status')}</Option>
                    <Option value="CANCEL">{t('job_cancel_status')}</Option>
                    <Option value="COMPLETE">{t('job_complete_status')}</Option>
                </Select>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    data: getUnfinishedRoundsData(state),
    totalItems: getTotalNumberOfElementsData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUnfinishedRounds: (data: any) => dispatch(getUnfinishedRounds(data)),
    applyAction: (data: any) => dispatch(applyAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnfinishedRoundsPage);
