import * as React from 'react';
import { connect } from 'react-redux';
import 'ConfigurationProvider/Lists/Operators/OperatorsStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import {
    changeOperatorStatus,
    getAllOperators,
    logoutAllOperatorSessions,
    refreshServers,
} from 'redux/actions/configProvider/operators-actions';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { Button, Dropdown, Empty, MenuProps, Modal } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import { IOperatorsData } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import {
    CheckCircleTwoTone,
    ControlOutlined,
    DollarCircleOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlayCircleOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    StopOutlined,
    SyncOutlined,
} from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import {
    getLoadingStatus,
    getOperators,
    isStatusChanged,
} from 'redux/selectors/configProvider/operators-selectors';
import CustomTable from '../../../Components/Table/CustomTable';
import { CP_OperatorsData } from '../../../Containers/Reports/columnsData';
import { sortAllOperators } from '../../../redux/actions/configProvider/operators-actions';
import { getSortedOperators } from '../../../redux/selectors/configProvider/operators-selectors';

interface IProps {
    data: IOperatorsData[];
    sortedData: IOperatorsData[];
    isStatusChanged: boolean | null;
    getOperators: Function;
    changeOperatorStatus: Function;
    refreshServers: Function;
    sortHandler: Function;
    loading: boolean;
}

interface IState {
    isModalVisible: boolean;
    isLogoutModalVisible: boolean;
    operatorIdToLogout: number;
    operatorNameToLogout: string;
    loading: boolean;
}

class Operators extends React.Component<IProps & WithTranslation> {
    state: IState = {
        isModalVisible: false,
        isLogoutModalVisible: false,
        operatorIdToLogout: 0,
        operatorNameToLogout: '',
        loading: false,
    };

    static getDerivedStateFromProps(
        props: IProps & WithTranslation,
        state: IState,
    ): Partial<IState> | null {
        if (state.loading && !props.loading && state.isModalVisible) {
            return { loading: false, isLogoutModalVisible: false };
        }
        return { loading: props.loading };
    }

    handleOperatorStatusChange = (enabled: boolean, id: number) => {
        this.props.changeOperatorStatus({ enabled: !enabled, id });
    };

    getActionsMenuItems = (operator: IOperatorsData): MenuProps['items'] => {
        const { enabled, id } = operator;
        const { t } = this.props;
        let key = 0;

        const items = [];

        const statusItem = enabled
            ? {
                  key: `${key++}`,
                  label: (
                      <div onClick={() => this.handleOperatorStatusChange(enabled, id)}>
                          <StopOutlined />
                          {t('disable_operator')}
                      </div>
                  ),
                  className: 'cp-actions__menu__red',
              }
            : {
                  key: `${key++}`,
                  label: (
                      <div onClick={() => this.handleOperatorStatusChange(enabled, id)}>
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                          {t('enable_operator')}
                      </div>
                  ),
                  className: 'cp-actions__menu__green',
              };

        items.push(statusItem);

        const links = [
            {
                url: `/config-provider/operators/${id}/manage-currencies`,
                icon: <DollarCircleOutlined />,
                text: t('manage_currencies'),
            },
            {
                url: `/config-provider/operators/${id}/manage-games`,
                icon: <PlayCircleOutlined />,
                text: t('manage_games'),
            },
            {
                url: `/config-provider/operators/${id}/game-configurations`,
                icon: <EditOutlined />,
                text: t('edit_game_configurations'),
            },
            {
                url: `/config-provider/operators/${id}/add-brand`,
                icon: <PlusOutlined />,
                text: t('add_brand'),
            },
            {
                url: `/config-provider/operators/${id}/edit-brand`,
                icon: <ControlOutlined />,
                text: t('manage_brands'),
            },
        ];

        for (const link of links) {
            const menuItem = {
                key: `${key++}`,
                label: (
                    <Link to={link.url}>
                        {link.icon}
                        {link.text}
                    </Link>
                ),
                className: 'cp-actions__menu',
            };
            items.push(menuItem);
        }

        return items;
    };

    getActions = (operator: IOperatorsData) => (
        <Dropdown menu={{ items: this.getActionsMenuItems(operator) }} trigger={['click']}>
            <Button className="cp-actions" icon={<EllipsisOutlined className="menu-icon" />} />
        </Dropdown>
    );

    sortHandler = (key: string, sortState: string) =>
        this.props.sortHandler({ keyToSort: key, sortState });

    handleModal = () => this.setState({ isModalVisible: true });

    onModalConfirm = () => {
        this.props.refreshServers();
        this.setState({ isModalVisible: false });
    };

    onModalCancel = () => this.setState({ isModalVisible: false });

    supplementWithLinks = (data: IOperatorsData[]) => {
        data.forEach((operator: IOperatorsData) => {
            operator.link = `/config-provider/operator/${operator.id}`;
        });

        return data;
    };

    componentDidMount = () => {
        this.props.getOperators();
    };

    render = () => {
        const { data, sortedData, t } = this.props;
        const operatorsData =
            sortedData && sortedData.length ? sortedData : data ? (data.length ? data : []) : null;

        return (
            <>
                <ContentComponent
                    header={
                        <>
                            <div className="header-line cp-header-line">
                                <HeaderTitleComponent
                                    title={t('operators')}
                                    customBreadcrumbs={<div>{t('all')}</div>}
                                />
                                <div className="cp-header-buttons">
                                    <Button
                                        shape="round"
                                        onClick={this.handleModal}
                                        style={{ marginRight: '8px' }}
                                        icon={<SyncOutlined />}
                                    >
                                        {t('refresh_servers')}
                                    </Button>
                                    <Link
                                        id="cp-header-button__wrapper"
                                        to={`/config-provider/operators/add-operator`}
                                    >
                                        <Button
                                            shape="round"
                                            type="primary"
                                            icon={<PlusCircleOutlined />}
                                        >
                                            {t('add_operator')}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                    innerContent={
                        operatorsData ? (
                            operatorsData.length ? (
                                <CustomTable
                                    reportKey={'CP_OperatorsData'}
                                    dataSource={this.supplementWithLinks(operatorsData)}
                                    columns={CP_OperatorsData}
                                    sortHandler={this.sortHandler}
                                    actionsMenu={this.getActions}
                                    clickableRow
                                    withoutCollapsing
                                    hidePagination
                                />
                            ) : (
                                <Empty description={t('no_data')} />
                            )
                        ) : (
                            <Loader style={{ height: '90vh' }} />
                        )
                    }
                />
                <Modal
                    title={t('refresh_servers')}
                    open={this.state.isModalVisible}
                    onOk={this.onModalConfirm}
                    onCancel={this.onModalCancel}
                    className="refresh-modal"
                    centered
                    footer={[
                        <Button
                            key="cancel"
                            onClick={this.onModalCancel}
                            className="refresh-modal__button refresh-modal__button__gray"
                        >
                            {t('cancel')}
                        </Button>,
                        <Button
                            key="confirm"
                            onClick={this.onModalConfirm}
                            className="refresh-modal__button refresh-modal__button__red"
                        >
                            {t('confirm')}
                        </Button>,
                    ]}
                >
                    <p>{t('refresh_servers_modal_message')}</p>
                </Modal>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getOperators(state),
    sortedData: getSortedOperators(state),
    isStatusChanged: isStatusChanged(state),
    loading: getLoadingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperators: () => dispatch(getAllOperators()),
    sortHandler: (data: any) => dispatch(sortAllOperators(data)),
    changeOperatorStatus: (data: any) => dispatch(changeOperatorStatus(data)),
    refreshServers: () => dispatch(refreshServers()),
    logoutOperator: (data: any) => dispatch(logoutAllOperatorSessions(data)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Operators);
export default withTranslation()(ConnectedComponent);
