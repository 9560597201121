import * as React from 'react';
import { connect } from 'react-redux';
import './OperatorGamesConfigStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { Loader } from 'Components/Loader/Loader';
import { IGameCurrencyConfig } from 'helpers/interfaces';
import { currencyUtils, CPUtils } from 'utils';
import {
    getOperatorGameConfigurations,
    updateOperatorGameConfigurations,
} from 'redux/actions/configProvider/operators-actions';
import { Button, Input } from 'antd';
import { CloseOutlined, UpOutlined } from '@ant-design/icons/lib';
import { Link, Navigate } from 'react-router-dom';
import { Footer } from 'Components/Footer/Footer';
import { getOperatorCurrenciesRequest } from 'redux/actions/configProvider/manage-brand-actions';
import {
    getCurrentOperatorGameConfigs,
    getGameConfigUpdateStatus,
} from 'redux/selectors/configProvider/operators-selectors';
import { getOperatorCurrenciesIds } from 'redux/selectors/configProvider/manage-brand-selectors';
import { ChangeEvent } from 'react';
import { withRouter } from '../../../../helpers/HOCs/withRouter';

const { TextArea } = Input;

interface ICustomConfig {
    currencyId: number;
    isCollapsed: boolean;
    isValid: boolean;
    gameConfig?: any;
}

interface IProps {
    params: any;

    getOperatorCurrencies: Function;
    operatorCurrencyIds: number[];

    getOperatorGameConfigurations: Function;
    updateGameConfigurations: Function;
    configs: IGameCurrencyConfig[];
    isGameConfigsUpdated: boolean;
}

interface IState {
    configurations: ICustomConfig[];
}

class OperatorGameConfigurations extends React.Component<IProps & WithTranslation> {
    state: IState = {
        configurations: [],
    };

    getConfigs = () => {
        const { configurations } = this.state;

        return (
            <div className="cp-operator-game-configs">
                {configurations
                    .map((configuration: ICustomConfig, index: number) => {
                        const { currencyId, gameConfig, isCollapsed, isValid } = configuration;
                        const { code, id, name } = currencyUtils.getCurrencyDataById(
                            Number(currencyId),
                        );

                        return (
                            <div className="currency-item" key={code}>
                                <div className="arrow">
                                    <UpOutlined rotate={isCollapsed ? 180 : 0} />
                                </div>
                                <h3
                                    onClick={(e: React.MouseEvent<HTMLHeadElement>) =>
                                        this.handleCollapsing(e, index)
                                    }
                                >
                                    {code} - {name} <span className="gray">{id}</span>
                                </h3>
                                <div
                                    className={`currency-content ${
                                        isCollapsed ? ' collapsed' : ''
                                    }`}
                                >
                                    <TextArea
                                        style={{ backgroundColor: '#0B1022' }}
                                        defaultValue={gameConfig}
                                        rows={10}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                            this.onChange(e, index)
                                        }
                                    />
                                    <p className="error">
                                        {!isValid && this.props.t('json_not_valid')}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                    .sort((a: any, b: any) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0))}
            </div>
        );
    };

    handleCollapsing = (e: React.MouseEvent<HTMLHeadElement>, index: number) => {
        const { configurations } = this.state;

        configurations[index].isCollapsed = !configurations[index].isCollapsed;

        this.setState({ configurations });
    };

    onChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const { configurations } = this.state;
        const { value } = e.target;

        configurations[index].gameConfig = value;
        configurations[index].isValid = true;

        this.setState({ configurations });
    };

    expandAll = () => {
        const { configurations } = this.state;

        configurations.forEach(
            (config: ICustomConfig, index: number) => (configurations[index].isCollapsed = false),
        );

        this.setState({ configurations });
    };

    collapseAll = () => {
        const { configurations } = this.state;

        configurations.forEach(
            (config: ICustomConfig, index: number) => (configurations[index].isCollapsed = true),
        );

        this.setState({ configurations });
    };

    handleSubmit = () => {
        const { operatorId } = this.props.params;
        const { configurations } = this.state;
        const configs: any = {};
        let isValid = true;

        configurations.forEach((configuration: ICustomConfig, index: number) => {
            if (configuration.gameConfig) {
                try {
                    const parsedConfig = JSON.parse(`{${configuration.gameConfig}}`);

                    for (const key in parsedConfig) {
                        if (Object.prototype.hasOwnProperty.call(parsedConfig, key)) {
                            configs[key] = JSON.stringify(parsedConfig[key]);
                        }
                    }
                } catch (e) {
                    configurations[index].isValid = false;
                    isValid = false;
                }
            }
        });

        this.setState({ configurations });

        if (isValid) {
            this.props.updateGameConfigurations({
                defaultConfigIdToGameConfigSettings: {
                    ...this.getGameConfigToSend(),
                },
                operatorId,
            });
        }
    };

    getGameConfigToSend = () => {
        const { configurations } = this.state;
        const configToSend: any = {};

        configurations.forEach((configuration: ICustomConfig) => {
            const { gameConfig } = configuration;

            if (gameConfig) {
                const config = JSON.parse(`{${gameConfig}}`);

                for (const key in config) {
                    if (
                        Object.prototype.hasOwnProperty.call(config, key) &&
                        typeof config[key] === 'object' &&
                        config[key] !== null
                    ) {
                        configToSend[key] = JSON.stringify(config[key]);
                    }
                }
            }
        });

        return configToSend;
    };

    componentDidUpdate = (prevProps: IProps & WithTranslation, prevState: IState) => {
        const { configs, operatorCurrencyIds } = this.props;

        if (operatorCurrencyIds && configs && configs?.length && !prevState.configurations.length) {
            const configurations: ICustomConfig[] = [];
            const getRelevantConfig = (currencyId: number) =>
                configurations.filter(
                    (config: ICustomConfig) => config.currencyId === currencyId,
                )[0];

            operatorCurrencyIds.forEach((currency: any) =>
                configurations.push({
                    currencyId: currency.currencyId,
                    gameConfig: '',
                    isCollapsed: true,
                    isValid: true,
                }),
            );

            configs.forEach((config: IGameCurrencyConfig) => {
                const { gameConfig, currencyId, gameId } = config;
                const relevantConfig = getRelevantConfig(currencyId);
                const configId = gameId * 10000 + currencyId;

                if (gameConfig) {
                    relevantConfig.gameConfig += `${
                        relevantConfig.gameConfig.length > 0 ? ',\n' : ''
                    }"${configId}": ${gameConfig}`;
                    relevantConfig.isCollapsed = false;
                }
            });

            configurations.forEach((config: ICustomConfig, index: number) => {
                if (config.gameConfig) {
                    configurations[index].gameConfig = `${config.gameConfig}`;
                }
            });

            this.setState({ configurations });
        }
    };

    componentDidMount = () => {
        const { operatorId } = this.props.params;

        this.props.getOperatorGameConfigurations(operatorId);
        this.props.getOperatorCurrencies(operatorId);
    };

    render = () => {
        const { t, params, isGameConfigsUpdated } = this.props;
        const { configurations } = this.state;
        const operatorName = CPUtils.operatorIdToName(params.operatorId);

        return isGameConfigsUpdated ? (
            <Navigate to={`/config-provider/operators`} />
        ) : (
            <ContentComponent
                header={
                    <>
                        <div className="header-line cp-header-line">
                            <HeaderTitleComponent
                                title={t('edit_game_configurations')}
                                customBreadcrumbs={
                                    <>
                                        <Link to={`/config-provider/operators`}>
                                            {t('operators')}
                                        </Link>
                                        <span className="breadcrumbs-separator">/</span>
                                        <Link to={`/config-provider/operator/${params.operatorId}`}>
                                            {operatorName}
                                        </Link>
                                    </>
                                }
                            />
                            <Link to={`/config-provider/operators`}>
                                <CloseOutlined className="close-button" />
                            </Link>
                        </div>
                        <div className="cp-operator-upper-buttons">
                            <div className="btn white" onClick={this.expandAll}>
                                {t('expand_all')}
                            </div>
                            <div className="btn white" onClick={this.collapseAll}>
                                {t('collapse_all')}
                            </div>
                        </div>
                    </>
                }
                innerContent={
                    configurations && configurations.length ? this.getConfigs() : <Loader />
                }
                footer={
                    <Footer>
                        <Button shape="round" type="primary" onClick={this.handleSubmit}>
                            {t('save')}
                        </Button>
                    </Footer>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    configs: getCurrentOperatorGameConfigs(state),
    operatorCurrencyIds: getOperatorCurrenciesIds(state),
    isGameConfigsUpdated: getGameConfigUpdateStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorGameConfigurations: (data: any) => dispatch(getOperatorGameConfigurations(data)),
    updateGameConfigurations: (data: any) => dispatch(updateOperatorGameConfigurations(data)),
    getOperatorCurrencies: (data: number) => dispatch(getOperatorCurrenciesRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(OperatorGameConfigurations)));
