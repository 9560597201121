import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { Button, Input, Switch } from 'antd';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import * as i18next from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import { localStorageHelper } from 'utils';
import { IGameItemData } from 'helpers/interfaces';
import '../commonCPStyles.scss';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { addGameRequest } from 'redux/actions/configProvider/games-actions';
import { Loader } from 'Components/Loader/Loader';
import { Link, Navigate } from 'react-router-dom';
import { Footer } from 'Components/Footer/Footer';
import {
    getGameConfigUpdateStatus,
    isGameAdding,
} from 'redux/selectors/configProvider/games-selectors';
import { ChangeEvent } from 'react';

interface IProps {
    t: i18next.TFunction;
    addGame: Function;
    adding: boolean;
    isGameConfigUpdated: boolean;
}

interface IValues {
    game_id: string;
    game_name: string;
    game_bet: string;
    game_fr_support?: boolean;
    game_bf_support?: boolean;
    gameConfig: any;
}

interface IState {
    values: IValues;
    errors: IValues;
}

type gameDataKeys = 'game_id' | 'game_name' | 'game_bet' | 'gameConfig';

const defaultValues: IValues = {
    game_id: '',
    game_name: '',
    game_bet: '',
    game_fr_support: false,
    game_bf_support: false,
    gameConfig: '',
};
const errorIds = {
    game_id: 'game_id_validation_error',
    game_name: 'game_name_validation_error',
    game_bet: 'game_bet_validation_error',
    gameConfig: 'json_not_valid',
};
const NO_CONFIG = 'please_add_default_config';

class AddGame extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        values: { ...defaultValues },
        errors: { ...defaultValues },
    };

    getInputItems = () => {
        const { t } = this.props;
        const { values, errors } = this.state;
        const inputKeys: gameDataKeys[] = ['game_id', 'game_name', 'game_bet'];

        return inputKeys.map((key: gameDataKeys) => (
            <div key={key} className="item">
                <div>
                    <label>
                        <span className="red-color">* </span>
                        {t(key)}
                    </label>
                    <Input
                        value={values[key]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(e, key)}
                    />
                </div>
                <p className="error">{t(errors[key])}</p>
            </div>
        ));
    };

    onChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        key: gameDataKeys,
    ) => {
        const { value } = e.target;

        this.setState((state) => ({
            values: { ...state.values, [key]: value },
            errors: { ...state.errors, [key]: '' },
        }));
    };

    onFreeRoundsSupportChange = (value: boolean) => {
        const { values } = this.state;

        this.setState({ values: { ...values, game_fr_support: value } });
    };

    onBuyFeatureSupportChange = (value: boolean) => {
        const { values } = this.state;

        this.setState({ values: { ...values, game_bf_support: value } });
    };

    handleSubmit = () => {
        if (this.isDataValid()) {
            const { game_id, game_name, game_bet, game_fr_support, game_bf_support, gameConfig } =
                this.state.values;
            const config = JSON.parse(`{${gameConfig}}`);

            for (const key in config) {
                if (Object.prototype.hasOwnProperty.call(config, key)) {
                    config[key] = JSON.stringify(config[key]);
                }
            }

            this.props.addGame({
                id: game_id,
                name: game_name,
                wager: game_bet,
                gameConfig: config,
                freeRoundsSupported: !!game_fr_support,
                buyFeatureSupported: !!game_bf_support,
            });
        }
    };

    isDataValid = () => {
        const errors = this.getValidationErrors();

        this.setState({ errors });

        return Object.values(errors).every((error: string) => error === '');
    };

    getValidationErrors = () => {
        const { game_id, game_name, game_bet, gameConfig } = this.state.values;
        const allGames = localStorageHelper.getChainedValue('user.allGames');
        const isNotUniqueID = allGames.some((game: IGameItemData) => +game_id === game.id);

        return {
            game_id:
                game_id.trim().length === 0 || isNotUniqueID || isNaN(+game_id)
                    ? errorIds.game_id
                    : '',
            game_name: game_name.trim().length < 2 ? errorIds.game_name : '',
            game_bet:
                game_bet.length && Number.isInteger(+game_bet) && +game_bet >= 0
                    ? ''
                    : errorIds.game_bet,
            gameConfig: this.validateConfig(gameConfig),
        };
    };

    validateConfig = (config: any) => {
        let validationMessage = '';

        if (config.length === 0) {
            return NO_CONFIG;
        }

        try {
            JSON.parse(`{${config}}`);
        } catch (e) {
            validationMessage = errorIds.gameConfig;
        }

        return validationMessage;
    };

    render = () => {
        const { t, adding, isGameConfigUpdated } = this.props;
        const { values, errors } = this.state;

        return isGameConfigUpdated ? (
            <Navigate to={`/config-provider/games`} />
        ) : (
            <ContentComponent
                header={
                    <HeaderTitleComponent
                        title={t('add_new_game')}
                        customBreadcrumbs={
                            <div className="text-lg text-gray-700">{t('games')}</div>
                        }
                    />
                }
                innerContent={
                    adding ? (
                        <div className="flex justify-center items-center h-full">
                            <Loader />
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <ItemComponentWrapper customClass="space-y-4">
                                {this.getInputItems()}
                                <div className="flex flex-col space-y-2 p-4 bg-white rounded shadow">
                                    <div>
                                        <label className="flex items-center space-x-2 font-medium text-gray-700">
                                            <span className="text-red-500">*</span>
                                            <span>{t('free_rounds')}</span>
                                        </label>
                                        <Switch
                                            checked={values.game_fr_support}
                                            onChange={(value: boolean) =>
                                                this.onFreeRoundsSupportChange(value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="flex items-center space-x-2 font-medium text-gray-700">
                                            <span className="text-red-500">*</span>
                                            <span>{t('buy_feature')}</span>
                                        </label>
                                        <Switch
                                            checked={values.game_bf_support}
                                            onChange={(value: boolean) =>
                                                this.onBuyFeatureSupportChange(value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="flex items-center space-x-2 font-medium text-gray-700">
                                            <span className="text-red-500">*</span>
                                            <span>{t('default_configuration')}</span>
                                        </label>
                                        <TextArea
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            value={values.gameConfig}
                                            rows={10}
                                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                                this.onChange(e, 'gameConfig')
                                            }
                                        />
                                        <p className="text-sm text-red-600">
                                            {t(errors.gameConfig)}
                                        </p>
                                    </div>
                                </div>
                            </ItemComponentWrapper>
                        </div>
                    )
                }
                footer={
                    <Footer className="flex items-center p-4 bg-gray-100">
                        <Link to={`/config-provider/games`}>
                            <Button shape="round">{t('cancel')}</Button>
                        </Link>
                        <Button shape="round" type="primary" onClick={this.handleSubmit}>
                            {t('save')}
                        </Button>
                    </Footer>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    adding: isGameAdding(state),
    isGameConfigUpdated: getGameConfigUpdateStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addGame: (data: IValues) => dispatch(addGameRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddGame));
