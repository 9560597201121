import * as React from 'react';
import './CustomTableStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ITableData } from 'Containers/Reports/columnsData';
import TableHeader from './TableHeader';
import { Pagination, Modal, Switch, Button } from 'antd';
import { currencyUtils, localStorageHelper } from 'utils';
import ItemComponentWrapper from '../ContentComponent/ItemComponentWrapper';
import { Overlay } from '../Overlay/Overlay';
import { getInnerCellContent, handleRowClick, addRowClasses } from './TableUtils';
import { withRouter } from '../../helpers/HOCs/withRouter';

type DateRange = {
    fromDate: Date;
    toDate: Date;
};

interface IProps {
    dataSource: any;
    columns: ITableData[];
    dateRange?: DateRange;
    sortHandler?: Function;
    currencyId?: number;
    totalLine?: any;
    showFilter?: boolean;
    reportKey?: string;
    tableTitle?: string | null;
    hidePagination?: boolean;
    clickableRow?: boolean;
    gmtOffset?: boolean;
    context?: any;
    actionsMenu?: any;
    withoutCollapsing?: boolean;
    customProps?: any;
    navigate: any;
}

interface IState {
    columnsFilterPopUpVisible: boolean;
    columnFilters: any;
    keyToSort: string;
    sortState: string | null;
    previousKey: string;
    dataStartPoint: number;
    dataEndPoint: number;
}

const DEFAULT_AMOUNT_OF_ITEMS = 500;
const DEFAULT_AMOUNT_OF_ITEMS_ON_PAGE = 50;
// eslint-disable-next-line
let INTERVAL_ID: any;

class CustomTable extends React.Component<IProps & WithTranslation, IState> {
    state: IState = CustomTable.getInitialState(this.props);

    divRef: React.RefObject<HTMLTableSectionElement> = React.createRef<HTMLTableSectionElement>();
    shouldDisplayFreeRoundsData = localStorageHelper.getChainedValue(
        'user.brandFeatures.supportsFreeRounds',
    );
    shouldDisplayBuyFeatureData = localStorageHelper.getChainedValue(
        'user.brandFeatures.supportsBuyFeature',
    );

    static getInitialState = (props: IProps) => {
        let keyToSort = '';
        let sortState = '';
        const { reportKey, hidePagination, columns } = props;
        const storedFilters = localStorageHelper.getChainedValue(
            `user.reports.columnsFilter.${reportKey}`,
        );
        const defaultFilters: any = {};

        columns.forEach((column: ITableData) => {
            defaultFilters[column.key] = true;
        });

        switch (reportKey) {
            case 'casino':
                keyToSort = 'from';
                sortState = 'desc';
                break;

            case 'games':
                keyToSort = 'gameName';
                sortState = 'asc';
                break;

            case 'players':
                keyToSort = 'login';
                sortState = 'asc';
                break;

            case 'playerProfile':
                keyToSort = 'endTime';
                sortState = 'desc';
                break;

            default:
                break;
        }

        return {
            columnsFilterPopUpVisible: false,
            columnFilters: storedFilters || defaultFilters,
            keyToSort,
            sortState,
            previousKey: keyToSort,
            dataStartPoint: 0,
            dataEndPoint: hidePagination
                ? DEFAULT_AMOUNT_OF_ITEMS
                : DEFAULT_AMOUNT_OF_ITEMS_ON_PAGE,
        };
    };

    getData = () => {
        const { dataStartPoint, dataEndPoint, columnFilters } = this.state;
        const { dataSource, columns, clickableRow, navigate } = this.props;
        const result: JSX.Element[] = [];

        dataSource &&
            dataSource.length &&
            dataSource.forEach((row: any, i: number) => {
                if (i >= dataStartPoint && i < dataEndPoint) {
                    const currentRow: JSX.Element[] = [];

                    columns.forEach((column: ITableData, j: number) => {
                        const {
                            totalLineOnly,
                            stickyPosition,
                            alignment,
                            key,
                            frData,
                            bfData,
                            dataIndex,
                        } = column;

                        if (!totalLineOnly) {
                            if (
                                (!frData && !bfData) ||
                                (frData && this.shouldDisplayFreeRoundsData) ||
                                (bfData && this.shouldDisplayBuyFeatureData)
                            ) {
                                if (columnFilters[column.key]) {
                                    currentRow.push(
                                        <td
                                            key={`${i}-${j}`}
                                            className={addRowClasses(stickyPosition)}
                                        >
                                            <span
                                                className={`app-table__span alignment-${alignment}`}
                                            >
                                                {getInnerCellContent(
                                                    this.props,
                                                    row,
                                                    dataIndex,
                                                    i,
                                                    key,
                                                )}
                                            </span>
                                        </td>,
                                    );
                                }
                            }
                        }
                    });
                    result.push(
                        <tr
                            key={`row-${i}`}
                            onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                                handleRowClick(this.props, row, e, navigate);
                            }}
                            className={`app-table__row${row.blocked ? ' blocked' : ''}${
                                clickableRow ? ' app-table__row__clickable' : ''
                            }${!row.finished && row.type === 'Bought' ? ' danger' : ''}`}
                        >
                            {currentRow}
                        </tr>,
                    );
                } else if (i > dataEndPoint) {
                    return false;
                }
            });

        return result;
    };

    sortStateHandler = (key: string) => {
        const { sortHandler } = this.props;
        const { previousKey } = this.state;
        let { sortState } = this.state;

        sortState = key === previousKey ? (sortState === 'desc' ? 'asc' : 'desc') : 'desc';

        sortHandler &&
            this.setState(
                () => ({
                    keyToSort: key,
                    sortState,
                    previousKey: key,
                }),
                () => sortHandler(key, sortState),
            );
    };

    paginationHandler = (page: number, pageSize: number) => {
        const shouldFixSecondColumn =
            this.props.reportKey === 'casino' || this.props.reportKey === 'playerProfile';

        this.setState(
            () => ({
                dataStartPoint: (page - 1) * pageSize,
                dataEndPoint: page * pageSize,
            }),
            () => {
                shouldFixSecondColumn && this.fixStickyColumns();
            },
        );
    };

    fixStickyColumns = () => {
        const { current } = this.divRef;

        if (current) {
            const stickyColumnsWidth: any = {};

            for (const cell in current.rows[0].cells) {
                const currentCell = current.rows[0].cells[cell];
                if (currentCell.className?.includes('sticky')) {
                    stickyColumnsWidth[cell] = currentCell.offsetWidth;
                }
            }

            for (const row in current.rows) {
                if (
                    Object.prototype.hasOwnProperty.call(current.rows, row) &&
                    current.rows[row] &&
                    current.rows[row].cells
                ) {
                    let currentOffset = 0;
                    for (const column in stickyColumnsWidth) {
                        const columnTitle: any = document.querySelector(`.sticky-title_${column}`);
                        current.rows[row].cells[Number(column)].style.position = 'sticky';
                        current.rows[row].cells[Number(column)].style.left = `${currentOffset}px`;
                        if (columnTitle) {
                            columnTitle.style.position = 'sticky';
                            columnTitle.style.left = `${currentOffset}px`;
                        }

                        currentOffset = currentOffset + stickyColumnsWidth[column];
                    }
                }
            }
        } else {
            INTERVAL_ID = setTimeout(this.fixStickyColumns, 500);
        }
    };

    componentDidMount(): void {
        INTERVAL_ID = setTimeout(this.fixStickyColumns, 500);

        window.addEventListener('resize', this.fixStickyColumns);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.fixStickyColumns);
    };

    showColumnFilter = () => {
        this.setState(() => ({ columnsFilterPopUpVisible: true }));
    };

    onColumnFilterOk = () => {
        this.setState(() => ({ columnsFilterPopUpVisible: false }));
    };

    changeModalState = () => {
        this.setState((state) => ({
            columnsFilterPopUpVisible: !state.columnsFilterPopUpVisible,
        }));
    };

    onColumnFilterChange = (status: boolean, columnKey: string) => {
        const filters = this.state.columnFilters;

        filters[columnKey] = status;
        localStorageHelper.setChainedValue(
            `user.reports.columnsFilter.${this.props.reportKey}`,
            filters,
        );
        this.setState(() => ({ columnFilters: filters }));
    };

    getPopup = () => {
        const { columnsFilterPopUpVisible, columnFilters } = this.state;
        const getColumnFilterItems = () => {
            const result = [];

            for (const filter in columnFilters) {
                const shouldAddCurrentFilter = () => {
                    let result = false;

                    this.props.columns.forEach((column: ITableData) => {
                        if (filter === column.key) {
                            if (
                                (!column.frData && !column.bfData) ||
                                (column.frData && this.shouldDisplayFreeRoundsData) ||
                                (column.bfData && this.shouldDisplayBuyFeatureData)
                            ) {
                                result = true;
                            }
                        }
                    });

                    return result;
                };

                if (shouldAddCurrentFilter()) {
                    result.push(
                        <div key={filter} className="switcher__outer">
                            <p className="">{this.props.t(filter)}</p>
                            <Switch
                                className={'switcher'}
                                defaultChecked={columnFilters[filter]}
                                onChange={(checked: boolean) =>
                                    this.onColumnFilterChange(checked, filter)
                                }
                            />
                        </div>,
                    );
                }
            }

            return result;
        };

        return (
            <>
                <p className={'columns-filter-button'} onClick={this.showColumnFilter}>
                    {this.props.t('columns_filter')}
                </p>
                <Modal
                    className={'app-table__column-filters'}
                    title={this.props.t('columns_filter')}
                    open={columnsFilterPopUpVisible}
                    onOk={this.onColumnFilterOk}
                    onCancel={this.onColumnFilterOk}
                    closable={false}
                    footer={[
                        <Button
                            shape="round"
                            type="primary"
                            key={'ok'}
                            onClick={this.onColumnFilterOk}
                        >
                            {this.props.t('ok')}
                        </Button>,
                    ]}
                >
                    {getColumnFilterItems()}
                </Modal>
                <Overlay
                    isVisible={columnsFilterPopUpVisible}
                    switchState={this.changeModalState}
                />
            </>
        );
    };

    render = () => {
        const {
            columns,
            sortHandler,
            dataSource,
            currencyId,
            tableTitle,
            reportKey,
            hidePagination,
        } = this.props;
        const { sortState, keyToSort, columnFilters } = this.state;
        const tableCurrencyId = currencyId || currencyUtils.getActiveCurrencyData().id;
        const shouldItemCollapsing =
            !this.props.withoutCollapsing &&
            reportKey !== 'playerProfile' &&
            reportKey !== 'usersComponent';

        return (
            <ItemComponentWrapper
                titleKey={tableTitle}
                collapsible={shouldItemCollapsing}
                customClass={reportKey === 'playerProfile' ? ' player-profile' : ''}
            >
                {this.props.showFilter ? this.getPopup() : null}
                <div
                    className={`app-table__wrapper${
                        reportKey === 'playerProfile' ? ' player-profile' : ''
                    }`}
                >
                    <table className="app-table">
                        <TableHeader
                            columns={columns}
                            currencyId={tableCurrencyId}
                            sortStateHandler={sortHandler && this.sortStateHandler}
                            keyToSort={keyToSort}
                            sortState={sortState}
                            columnFilters={columnFilters}
                        />
                        <tbody ref={this.divRef}>{this.getData()}</tbody>
                    </table>
                </div>
                {!hidePagination ? (
                    <Pagination
                        className="table-pagination"
                        onChange={(page: number, pageSize?: number | undefined) =>
                            this.paginationHandler(page, pageSize || 20)
                        }
                        total={(dataSource && dataSource.length) || 0}
                        defaultPageSize={DEFAULT_AMOUNT_OF_ITEMS_ON_PAGE}
                        pageSizeOptions={['20', '50', '100']}
                        showSizeChanger
                        onShowSizeChange={this.paginationHandler}
                    />
                ) : null}
            </ItemComponentWrapper>
        );
    };
}

export default withTranslation()(withRouter(CustomTable));
