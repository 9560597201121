import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { Button, Switch } from 'antd';
import {
    getBrandGamesRequest,
    updateBrandGamesRequest,
} from 'redux/actions/configProvider/manage-brand-actions';
import { BRAND_STEPS } from 'ConfigurationProvider/ManageBrands/AddBrand';
import { Loader } from 'Components/Loader/Loader';
import { Footer } from 'Components/Footer/Footer';
import { IBrandData, IBrandGameData, IOperatorData, IOperatorGameData } from 'helpers/interfaces';
import {
    getBrandGames,
    isBrandDataLoading,
} from 'redux/selectors/configProvider/manage-brand-selectors';
import { localStorageHelper } from 'utils';

interface IProps {
    operatorData: IOperatorData;
    brandData: IBrandData;
    updateBrandData: Function;
    getBrandGames: Function;
    updateBrandGames: Function;
    brandGames?: IBrandGameData[];
    loading: boolean;
    previousStep: Function;
    nextStep: Function;
    t: i18next.TFunction;
    isExistingBrand?: boolean;
}

interface IState {
    games: any[];
    isModalVisible: boolean;
    loading: boolean;
}

const COLUMNS: string[] = [
    'game_name',
    'id',
    'game_status',
    'free_rounds_status',
    'buy_feature_status',
];

class StepManageBrandGames extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        games: [],
        isModalVisible: false,
        loading: false,
    };

    changeAllStatuses = (frozen: boolean) => {
        const { games } = this.state;

        games.forEach((game: any) => (game.frozen = frozen));

        this.setState({ games });
    };

    onChange = (checked: boolean, gameId: number) => {
        const { games } = this.state;

        games.forEach((game: any) => {
            if (gameId === game.id) {
                game.frozen = !checked;
            }
        });

        this.setState({ games });
    };

    onFreeRoundsChange = (checked: boolean, gameId: number) => {
        const { games } = this.state;

        games.forEach((game: any) => {
            if (gameId === game.id) {
                game.freeRoundsEnabled = checked;
            }
        });

        this.setState({ games });
    };

    onBuyFeatureChange = (checked: boolean, gameId: number) => {
        const { games } = this.state;

        games.forEach((game: any) => {
            if (gameId === game.id) {
                game.buyFeatureEnabled = checked;
            }
        });

        this.setState({ games });
    };

    handleSubmit = () => {
        const { games } = this.state;

        this.props.updateBrandGames({
            brandId: this.props.brandData.id,
            games,
        });
    };

    previousStep = () => this.props.previousStep();

    nextStep = () => {
        this.props.nextStep();
        this.props.updateBrandData({ gameIds: this.props.brandGames });
    };

    getTable = () => {
        const { games } = this.state;

        return (
            <table>
                <thead>
                    <tr>
                        {COLUMNS.map((columnTitle: string) => (
                            <th key={columnTitle}>{this.props.t(columnTitle)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {games.map((game: any) => (
                        <tr key={game.name}>
                            <td>{game.name}</td>
                            <td>{game.gameId}</td>
                            <td>
                                <Switch
                                    checked={!game.frozen}
                                    onChange={(checked: boolean) =>
                                        this.onChange(checked, game.gameId)
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={game.freeRoundsEnabled}
                                    onChange={(checked: boolean) =>
                                        this.onFreeRoundsChange(checked, game.gameId)
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={game.buyFeatureEnabled}
                                    onChange={(checked: boolean) =>
                                        this.onBuyFeatureChange(checked, game.gameId)
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    componentDidUpdate = () => {
        const { brandGames } = this.props;

        if (brandGames && !this.state.games.length) {
            const allGames = localStorageHelper.getChainedValue('user.allGames');
            const supplementedGameData: any = [];

            brandGames.forEach((brandGame: any) => {
                const gameData = allGames.find((game: any) => brandGame.gameId === game.id);

                supplementedGameData.push({
                    ...brandGame,
                    ...gameData,
                });
            });

            this.setState({ games: supplementedGameData });
        }
    };

    getSortedGames = () => {
        const gamesData = [...this.props.operatorData.operatorGamesData];

        gamesData.sort((a: IOperatorGameData, b: IOperatorGameData) =>
            a.gameId < b.gameId ? 1 : -1,
        );

        return gamesData;
    };

    componentDidMount = () => {
        const { brandData } = this.props;

        if (brandData.id) {
            this.props.getBrandGames(brandData.id);
        }
    };

    render = () => {
        const { t, loading } = this.props;

        return (
            <>
                <div className="operator-step step-wrapper manage-games">
                    {!loading ? (
                        <>
                            <h2 className="title">{t(BRAND_STEPS[1])}</h2>
                            <div className="buttons">
                                <div
                                    className="btn grey"
                                    onClick={() => this.changeAllStatuses(true)}
                                >
                                    {t('disable_all')}
                                </div>
                                <div
                                    className="btn grey"
                                    onClick={() => this.changeAllStatuses(false)}
                                >
                                    {t('enable_all')}
                                </div>
                            </div>
                            {this.getTable()}
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
                <Footer>
                    <Button shape="round" onClick={this.previousStep}>
                        {t('back')}
                    </Button>
                    <Button shape="round" onClick={this.nextStep}>
                        {t('next')}
                    </Button>
                    <Button shape="round" type="primary" onClick={this.handleSubmit}>
                        {t('continue_save')}
                    </Button>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    loading: isBrandDataLoading(state),
    brandGames: getBrandGames(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBrandGames: (data: any) => dispatch(getBrandGamesRequest(data)),
    updateBrandGames: (data: any) => dispatch(updateBrandGamesRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(StepManageBrandGames));
