import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import './AddOperatorStyles.scss';
import { STEPS } from './index';
import * as i18next from 'i18next';
import { localStorageHelper } from 'utils';
import { IGameItemData } from 'helpers/interfaces';
import { Button, Switch } from 'antd';
import { Dispatch } from 'redux';
import { addOperatorGamesRequest } from 'redux/actions/configProvider/add-operator-actions';
import { Footer } from 'Components/Footer/Footer';

interface IProps {
    previousStep: Function;
    addGames: Function;
    t: i18next.TFunction;
}

interface IState {
    games: IGameItemData[];
}

const COLUMNS: string[] = [
    'game_name',
    'id',
    'game_status',
    'free_rounds_status',
    'buy_feature_status',
];

class StepManageGames extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        games: StepManageGames.getOperatorGames(),
    };

    static getOperatorGames = () => {
        const games = localStorageHelper.getChainedValue('user.allGames');
        const storedGames = localStorageHelper.getChainedValue('user.newOperator.gameIds');

        if (storedGames && storedGames.length) {
            games.forEach((game: IGameItemData, index: number) => {
                games[index].status = storedGames.indexOf(games[index].id) >= 0;
                games[index].freeRoundsEnabled = false;
                games[index].buyFeatureEnabled = false;
            });
        } else {
            games.forEach((game: IGameItemData, index: number) => {
                games[index].status = true;
                games[index].freeRoundsEnabled = false;
                games[index].buyFeatureEnabled = false;
            });
        }

        games.sort((a: IGameItemData, b: IGameItemData) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
        );

        return games;
    };

    disableAll = () => {
        const { games } = this.state;

        games.forEach((game: IGameItemData, index: number) => (games[index].status = false));

        this.setState({ games });
    };

    enableAll = () => {
        const { games } = this.state;

        games.forEach((game: IGameItemData, index: number) => (games[index].status = true));

        this.setState({ games });
    };

    onChange = (checked: boolean, index: number) => {
        const { games } = this.state;

        games[index].status = checked;

        this.setState({ games });
    };

    onFreeRoundsChange = (checked: boolean, index: number) => {
        const { games } = this.state;

        games[index].freeRoundsEnabled = checked;

        this.setState({ games });
    };

    onBuyFeatureChange = (checked: boolean, index: number) => {
        const { games } = this.state;

        games[index].buyFeatureEnabled = checked;

        this.setState({ games });
    };

    handleSubmit = () => {
        const operatorId = localStorageHelper.getChainedValue('user.newOperator.id');
        const games: any[] = [];

        this.state.games.forEach(
            (game: IGameItemData) =>
                game.status &&
                games.push({
                    id: game.id,
                    freeRoundsEnabled: game.freeRoundsEnabled,
                    buyFeatureEnabled: game.buyFeatureEnabled,
                    frozen: false,
                }),
        );

        this.isContinueBtnEnabled() && this.props.addGames({ games, operatorId });
    };

    isContinueBtnEnabled = () => this.state.games.some((game: IGameItemData) => !!game.status);

    getBack = () => this.props.previousStep();

    getTable = () => (
        <table>
            <thead>
                <tr>
                    {COLUMNS.map((columnTitle: string) => (
                        <th key={columnTitle}>{this.props.t(columnTitle)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {this.state.games.map((game: IGameItemData, index: number) => (
                    <tr key={game.name}>
                        <td>{game.name}</td>
                        <td>{game.id}</td>
                        <td>
                            <Switch
                                checked={game.status}
                                onChange={(checked: boolean) => this.onChange(checked, index)}
                            />
                        </td>
                        <td>
                            <Switch
                                checked={game.freeRoundsEnabled}
                                onChange={(checked: boolean) =>
                                    this.onFreeRoundsChange(checked, index)
                                }
                            />
                        </td>
                        <td>
                            <Switch
                                checked={game.buyFeatureEnabled}
                                onChange={(checked: boolean) =>
                                    this.onBuyFeatureChange(checked, index)
                                }
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    render = () => {
        const { t } = this.props;

        return (
            <>
                <div className="operator-step step-wrapper manage-games">
                    <h2 className="title">{t(STEPS[1])}</h2>
                    <div className="buttons">
                        <div className="btn grey" onClick={this.disableAll}>
                            {t('disable_all')}
                        </div>
                        <div className="btn grey" onClick={this.enableAll}>
                            {t('enable_all')}
                        </div>
                    </div>
                    {this.getTable()}
                </div>
                <Footer>
                    <Button shape="round" onClick={this.getBack}>
                        {t('back')}
                    </Button>
                    <Button
                        shape="round"
                        type="primary"
                        disabled={!this.isContinueBtnEnabled()}
                        onClick={this.handleSubmit}
                    >
                        {t('continue_save')}
                    </Button>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addGames: (data: any) => dispatch(addOperatorGamesRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StepManageGames));
