import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    getAwardedCampaignData,
    getAwardedCampaignSummaryData,
    getPromotionsErrorData,
} from '../../../../redux/selectors/backoffice/propmotions-selectors';
import {
    clearCampaignData,
    editAwardedCampaign,
    getAwardedCampaign,
} from '../../../../redux/actions/backoffice/promotions-actions';
import { useDispatch, useSelector } from 'react-redux';
import { ContentComponent } from '../../../../Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from '../../../../Components/ContentComponent/HeaderTitleComponent';
import { ErrorComponent } from '../../../Errors/ErrorComponent';
import { Loader } from '../../../../Components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Tabs } from 'antd';
import CampaignHeaderButton from './CampaignHeaderButton';
import { localStorageHelper } from '../../../../utils';
import { currencyUtils } from 'utils';
import PromotionalTotalComponent from './PromotionalTotalComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import ConfigurationTab from './Tabs/ConfigurationTab';
import PlayersTab from './Tabs/PlayersTab';
import GamesTab from './Tabs/GamesTab';
import { validationSchema } from './Tabs/validationSchema';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs, { Dayjs } from 'dayjs';
import { IGameItemData } from '../../../../helpers/interfaces';

interface IAwardedCampaignProps {
    campaign_name: string;
    timezone: 'Etc/UTC';
    start_at: Dayjs;
    end_at: Dayjs;
    number_of_players: number;
    valid_for: number | null;
    strategy: string;
    start_event: string;
    currency: string;
    subunit: number;
    price: number | null;
    number_of_features: number | null;
    gameIds: string[];
    allowPostExpirationCompletion: boolean;
    rules_url: string;
}

const getInitialValues = (): IAwardedCampaignProps => {
    return {
        campaign_name: '',
        timezone: 'Etc/UTC',
        start_at: dayjs(),
        end_at: dayjs(),
        number_of_players: 1000,
        valid_for: null,
        strategy: 'FIRST_N_PLAYERS',
        start_event: 'ACCEPT',
        currency: 'USD',
        subunit: 100,
        price: 0,
        number_of_features: null,
        gameIds: [],
        allowPostExpirationCompletion: false,
        rules_url: '',
    };
};

const AwardedCampaignControl = () => {
    const params = useParams();
    const { edit } = params || null;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialValues = getInitialValues();
    const campaignData = useSelector(getAwardedCampaignData);
    const campaignSummary = useSelector(getAwardedCampaignSummaryData);
    const error = useSelector(getPromotionsErrorData);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema(t, campaignData?.status),
        onSubmit: () => {
            setConfirmVisible(true);
        },
    });
    const formikRef = useRef(formik);

    useEffect(() => {
        formikRef.current = formik;
    }, [formik]);

    const userActiveCurrency = currencyUtils.getActiveCurrencyData();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('configuration');
    const [currency, setCurrency] = useState<'user' | 'campaign'>(
        userActiveCurrency.billable ? 'user' : 'campaign',
    );
    const [isEditMode, setIsEditMode] = useState(!!edit);
    const [isManagePlayersMode, setIsManagePlayersMode] = useState(false);
    const [currencyCode, setCurrencyCode] = useState(
        currency === 'user' ? userActiveCurrency.code : '',
    );

    const handleUpdateCurrency = (value: 'user' | 'campaign') => {
        setCurrency(value);
        setCurrencyCode(value === 'user' ? userActiveCurrency.code : campaignData.currencyCode);
    };

    const isUserCanManageCampaigns = localStorageHelper
        .getChainedValue('user.authorities')
        .includes('MANAGE_CAMPAIGNS');

    const isFieldDisabled = (fieldName: string) => {
        if (!isEditMode) return true;

        if (!campaignData) return true;

        if (campaignData.status === 'ONGOING') {
            if (fieldName !== 'rules_url' && fieldName !== 'gameIds') {
                return true;
            }
        } else if (campaignData.status === 'ENDED' || campaignData.status === 'DISABLED') {
            return true;
        }

        return ['price', 'currency'].includes(fieldName) && formik.values.gameIds.length > 0;
    };

    const handleManagePlayersToggle = () => {
        setIsManagePlayersMode((prev) => !prev);
    };

    const handleDiscardChanges = () => {
        setCampaignDataValues();
        setIsEditMode(false);
    };

    const handleEditSaveToggle = () => {
        if (
            !campaignData ||
            campaignData.status === 'ENDED' ||
            campaignData.status === 'DISABLED'
        ) {
            return;
        }

        if (isEditMode) {
            formik.handleSubmit();
        } else {
            setIsEditMode(true);
        }
    };

    const requestCampaign = useCallback(() => {
        dispatch(
            getAwardedCampaign({
                campaignId: params.campaignId,
                currency: currency,
            }),
        );
    }, [dispatch, params.campaignId, currency]);

    useEffect(() => {
        requestCampaign();
    }, [requestCampaign]);

    const handleConfirmSubmit = () => {
        if (campaignData) {
            dispatch(editAwardedCampaign({ ...formik.values, campaignId: campaignData.id }));
            setConfirmVisible(false);
            setIsEditMode(false);
        }
    };

    const setCampaignDataValues = useCallback(() => {
        if (campaignData) {
            const newValues: any = {
                start_at: dayjs(campaignData.startDate),
                end_at: dayjs(campaignData.endDate),
                campaign_name: campaignData.name,
                timezone: campaignData.timezone,
                number_of_players: campaignData.eligiblePlayersNumber,
                valid_for: campaignData.validForDays ? campaignData.validForDays : null,
                strategy: campaignData.strategy,
                currency: campaignData.currencyCode,
                subunit: currencyUtils.getCurrencyDataByCode(campaignData.currencyCode).subunit,
                price: campaignData.bet,
                number_of_features: campaignData.betNumber,
                gameIds: campaignData.games.map((game: IGameItemData) => game.id),
                start_event: campaignData.startEvent,
                allowPostExpirationCompletion: Boolean(campaignData.allowPostExpirationCompletion),
                rules_url: campaignData.rulesUrl,
            };

            if (JSON.stringify(formikRef.current.values) !== JSON.stringify(newValues)) {
                formikRef.current.setValues(newValues);
                formikRef.current.validateForm();
            }
        }
    }, [campaignData]);

    const tabHasErrors = (fields: Array<keyof IAwardedCampaignProps>) =>
        fields.some((field) => formik.errors[field] && formik.touched[field]);

    const tabsFields: any = {
        configuration: [
            'campaign_name',
            'timezone',
            'start_at',
            'end_at',
            'valid_for',
            'start_event',
            'allowPostExpirationCompletion',
            'rules_url',
        ],
        players: ['number_of_players', 'strategy', 'players'],
        games: ['currency', 'price', 'number_of_features', 'gameIds'],
    };

    useEffect(() => {
        setCampaignDataValues();
    }, [campaignData, setCampaignDataValues]);

    useEffect(() => {
        return () => {
            dispatch(clearCampaignData());
        };
    }, [dispatch]);

    return (
        <ContentComponent
            header={
                <div className="flex justify-between items-end mb-2">
                    <HeaderTitleComponent
                        title={campaignData ? campaignData.name : ''}
                        customBreadcrumbs={
                            <Link to={`/casino/awarded-promotions`}>{t('promotions')}</Link>
                        }
                    />
                    {isUserCanManageCampaigns &&
                    campaignData &&
                    (campaignData.status === 'ONGOING' ||
                        campaignData.status === 'UPCOMING' ||
                        campaignData.status === 'ENDED') ? (
                        <div>
                            <CampaignHeaderButton
                                id={params.campaignId}
                                status={campaignData.status}
                                data={campaignData}
                                requestCampaign={requestCampaign}
                            />
                        </div>
                    ) : undefined}
                </div>
            }
            preHeader={
                campaignSummary && !error ? (
                    <PromotionalTotalComponent
                        currentCurrencyCode={currencyCode}
                        data={{ ...campaignSummary, ...campaignData }}
                        onCurrencyChange={handleUpdateCurrency}
                    />
                ) : undefined
            }
            innerContent={
                error ? (
                    <ErrorComponent error={error} />
                ) : campaignData ? (
                    <div style={{ padding: 24, backgroundColor: '#fff', borderRadius: 8 }}>
                        <Modal
                            title="Edit Campaign?"
                            open={confirmVisible}
                            onOk={handleConfirmSubmit}
                            onCancel={() => setConfirmVisible(false)}
                        >
                            Are you sure you want to save changes?
                        </Modal>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Tabs
                                    activeKey={activeTab}
                                    onChange={(key) => setActiveTab(key)}
                                    tabBarExtraContent={
                                        <div>
                                            {isEditMode && !isManagePlayersMode && (
                                                <Button
                                                    onClick={handleDiscardChanges}
                                                    className="mr-2"
                                                >
                                                    Discard Changes
                                                </Button>
                                            )}
                                            {campaignData?.status !== 'ENDED' &&
                                                campaignData?.status !== 'DISABLED' && (
                                                    <Button
                                                        onClick={
                                                            activeTab === 'players'
                                                                ? handleManagePlayersToggle
                                                                : handleEditSaveToggle
                                                        }
                                                    >
                                                        {activeTab === 'players'
                                                            ? isManagePlayersMode
                                                                ? 'Quit Manage Players'
                                                                : 'Manage Players'
                                                            : isEditMode
                                                              ? 'Save'
                                                              : 'Edit'}
                                                    </Button>
                                                )}
                                        </div>
                                    }
                                >
                                    <TabPane
                                        tab={`Configuration ${tabHasErrors(tabsFields.configuration) ? '!' : ''}`}
                                        key="configuration"
                                        disabled={isManagePlayersMode}
                                    >
                                        <ConfigurationTab
                                            isViewMode={!isEditMode}
                                            isFieldDisabled={isFieldDisabled}
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab={`Players ${tabHasErrors(tabsFields.players) ? '!' : ''}`}
                                        key="players"
                                        disabled={isEditMode}
                                    >
                                        <PlayersTab
                                            campaignData={campaignData}
                                            isViewMode={!isManagePlayersMode}
                                            setIsViewMode={setIsManagePlayersMode}
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab={`Games ${tabHasErrors(tabsFields.games) ? '!' : ''}`}
                                        key="games"
                                        disabled={isManagePlayersMode}
                                    >
                                        <GamesTab
                                            formik={formik}
                                            campaignData={campaignData}
                                            isViewMode={!isEditMode}
                                            isFieldDisabled={isFieldDisabled}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Form>
                        </FormikProvider>
                    </div>
                ) : (
                    <Loader style={{ height: '90vh' }} />
                )
            }
        />
    );
};

export default AwardedCampaignControl;
